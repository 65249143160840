/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import Home from './components/Home/HomePage/HomePage';
import Scorecard from './components/Scorecard/ScorecardPage/ScorecardPage';
import History from './components/History/HistoryPage/HistoryPage';
import Admin from './components/Admin/AdminPage/AdminPage';
import Login from './components/Login/LoginPage/LoginPage';
import './App.scss';
import NotFound from './components/NotFound/NotFoundPage/NotFoundPage';
import { ApplicationState } from './store';
import { connect } from 'react-redux';
import * as AuthenticationStore from './store/Authentication/Authentication';
import * as ConfigurationStore from './store/Configuration/Configuration';
import MorningBrew from './components/MorningBrew/MorningBrewPage/MorningBrewPage';
import DailyForecast from './components/MorningBrew/DailyForecastPage/DailyForecastPage';
import ContentPanel from './components/Shared/ContentPanel/ContentPanel';
import ReleaseGuide from './components/Release/ReleaseGuide';
import ReleaseGuideCatalog from './components/Release/ReleaseGuideCatalog';
import PermissionHelper from './helpers/PermissionHelper';
import { Permissions } from './store/Authentication/Authentication';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import IdleHandler from './components/Shared/IdleHandler/IdleHandler';

interface AppProps {
  authentication: AuthenticationStore.AuthenticationState;
  configuration: ConfigurationStore.ConfigurationState;
}

function App(props: AppProps) {
  const { authentication, configuration } = props;
  const permHelper = new PermissionHelper(authentication.permissions);

  if(configuration.appInsightsConfig && window.location.hostname !== 'localhost') {
    const appInsights = new ApplicationInsights({ config: {
      connectionString: configuration.appInsightsConfig,
      autoTrackPageVisitTime: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableAutoRouteTracking: true,
    } });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }

  function hasAccess(){
    return permHelper.HasPermission(Permissions.ViewAdminDashboard) || permHelper.HasPermission(Permissions.ViewSuggestionsHistory);
  }

  return (
    <>
      <BrowserRouter>
        <ContentPanel>
          <AuthenticatedTemplate>
            <IdleHandler />
            <Routes>
              <Route path="/"
                element={permHelper.HasPermission(Permissions.ViewAdminDashboard) ? <Admin/> : <Home/>}
              />
              <Route path="/History"
                element={hasAccess() ? <History/> : <NotFound/>}
              />
              <Route path="/Admin"
                element={permHelper.HasPermission(Permissions.ViewAdminDashboard) ? <Admin/> : <NotFound/>}
              />
              <Route path="/Scorecard"
                element={hasAccess() ? <Scorecard/> : <NotFound/>}
              />
              <Route path="/morning-brew"
                element={hasAccess() ? <MorningBrew/> : <NotFound/>}
              />
              <Route path="/daily-forecast"
                element={hasAccess() ? <DailyForecast/> : <NotFound/>}
              />
              <Route
                path="/release-guides/"
                element={<ReleaseGuideCatalog/>}
              />
              <Route
                path="/release-guide/:releaseNumber"
                element={<ReleaseGuide/>}
              />

              <Route path="/*"
                element={<NotFound/>}
              />
            </Routes>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Routes>
              <Route path="/*"
                element={<Login />}
              />
            </Routes>
          </UnauthenticatedTemplate>
        </ContentPanel>
      </BrowserRouter>
    </>
  );
}

export default connect(
  (state: ApplicationState) => ({
    authentication: state.authentication,
    configuration: state.configuration,
  }),
)(App as any);