/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AuthenticationState {
    isLoading: boolean;
    isErrored: boolean;
    isAuthenticated: boolean;

    permissions: number[];
    tenantDetails?: TenantDetails;
    userDetails?: UserDetails;
    scoringEntities?: ScoringEntity[];
    selectedScoringEntity: string;

    errorMessage: string;
    mimicScoringEntity: string;
}

// these refer to the access.[Permission] table in the scorecards sql database
export enum Permissions {
    EditItemCategories = 0,
    EditItemCategorisation = 10,
    EditStoreBandings = 20,
    EditUserPermissions = 30,
    EditStoreAccess = 40,
    EditAreaAccess = 50,
    EditUserDetails = 60,
    EditScoreWeightings = 70,
    EditCompanies = 80,
    EditTenants = 90,
    ViewAdminDashboard = 100,
    CreateManualSuggestion = 110,
    ViewSuggestionsHistory = 120
}

export interface TenantDetails {
    tenantId: number,
    name: string,
    reportingWindow: string,
    logo: string,
    threshold: number
}

export interface UserDetails {
    name: string,
    job: string
}

export interface ScoringEntity {
    dataId: string,
    name: string,
    type: string,
    bandingId: number,
    owner?: Owner,
    openHour: string;
    closeHour: string;
    openHourSun: string;
    closeHourSun: string;
    latitude: number,
    longitude: number
}

export interface Owner {
    name: string,
    phoneNumber: string,
    emailAddress: string
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestUserDetailsAction extends AnyAction {
    type: 'REQUEST_USERDETAILS';
}

export interface ReceiveUserDetailsAction extends AnyAction {
    type: 'RECEIVE_USERDETAILS';
    payload: any;
}

export interface FailedUserDetailsAction extends AnyAction {
    type: 'FAILED_USERDETAILS';
}

export interface MimicStoreAction extends AnyAction {
    type: 'MIMIC_STORE';
}

export interface CancelMimicAction extends AnyAction {
    type: 'CANCEL_MIMIC';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestUserDetailsAction | ReceiveUserDetailsAction | FailedUserDetailsAction | MimicStoreAction | CancelMimicAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestUserDetails: ():
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.authentication && appState.authentication.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_USERDETAILS',
        http: {
          verb: 'GET',
          endpoint: '/api/authentication/GetSsoUserDetails',
          successAction: 'RECEIVE_USERDETAILS',
          failureAction: 'FAILED_USERDETAILS',
        }, 
      });
    }
  },  
  mimicStore: (storeId: string):
  AppThunkAction<any> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.authentication && appState.authentication.isLoading === false) {
      dispatch({ 
        type: 'MIMIC_STORE',
        payload: storeId,
      });
    }
  },
  cancelMimic: ():
  AppThunkAction<any> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.authentication && appState.authentication.isLoading === false) {
      dispatch({ 
        type: 'CANCEL_MIMIC',
      });
    }
  },
};

// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: AuthenticationState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isAuthenticated: false,
  permissions: [],
  selectedScoringEntity: '',
  mimicScoringEntity: '',
};

export const reducer: Reducer<AuthenticationState> = (state: AuthenticationState | undefined,
  incomingAction: Action):AuthenticationState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_USERDETAILS':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      permissions: [],
    };
  case 'RECEIVE_USERDETAILS':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isAuthenticated: true,
      permissions: action.payload.permissions,
      scoringEntities: action.payload.scoringEntities,
      tenantDetails: action.payload.tenantDetails,
      userDetails: action.payload.userDetails,
      selectedScoringEntity: action.payload.scoringEntities[0].dataId,
      mimicScoringEntity: '',
    };
  case 'FAILED_USERDETAILS':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving user details.',
      isErrored: true,
      isLoading: false,
      isAuthenticated: false,
      permissions: [],
    };

  case 'MIMIC_STORE':
    return {
      ...state,
      mimicScoringEntity: action.payload,
      selectedScoringEntity: action.payload,
    };

  case 'CANCEL_MIMIC':
    return {
      ...state,
      mimicScoringEntity: '',
    };

  default:
    return state;
  }
};

