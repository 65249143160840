import { Permissions } from '../store/Authentication/Authentication';

class PermissionHelper{
  private currentPermissions;
  constructor(currentPermissions: number[]){
    this.currentPermissions = currentPermissions;
  }

  HasPermission(permission: Permissions){
    return this.currentPermissions && this.currentPermissions.indexOf(permission) > -1;
  }
}

export default(PermissionHelper);
